<template>
  <div style="margin: 20px">
    <el-card>
      <el-form :model="form" v-loading="loading" :rules="rules" ref="form">
        <el-form-item label="Сокращение" prop="short_name">
          <el-input v-model="form.short_name"></el-input>
        </el-form-item>
        <el-form-item label="Полное наименование">
          <el-input v-model="form.full_name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save" :disabled="lockButton">{{ isCreate ? 'Создать' : 'Сохранить' }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { getUnit, saveUnit } from '@/api/dictionaries'
export default {
  data () {
    return {
      loading: false,
      lockButton: false,
      isCreate: false,
      form: {
        id: null,
        short_name: null,
        full_name: null
      },
      rules: {
        short_name: [
          {
            validator: (rule, value, callback) => {
              if (value?.trim() === '' || !value) {
                callback(new Error('Не заполнено обязательное поле'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted () {
    if (this.$route.name.includes('create')) {
      this.isCreate = true
    } else {
      this.fetchData()
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      getUnit(this.$route.params.id).then(response => {
        this.form = response.data
      }).finally(() => {
        this.loading = false
      })
    },
    save () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.lockButton = true
          saveUnit(this.form).then((response) => {
            this.$message({
              message: 'Успешно сохранено!',
              type: 'success'
            })
            if (this.isCreate) {
              this.$router.push({ name: this.$route.name.replace('create', 'view'), params: { id: response.data.id } })
            }
          }).finally(() => {
            this.lockButton = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
